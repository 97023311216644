import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Container,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody, CardTitle,
} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import FileDownload from "js-file-download"
import $ from "jquery"
import warning from "../../assets/images/téléchargement.png"

const ListEtudiantNoteDS2 = props => {
  if (localStorage.getItem("userAuthScolarite")) {
    var userAuthScolarite = JSON.parse(
        localStorage.getItem("userAuthScolarite")
    )
    var user_id = userAuthScolarite.user.id
  }
  const state_element = props.location.state.state_element
  const element = props.location.state.element
  const niveau_id = props.location.state.niveau_id
  const session_id = props.location.state.session_id
  const semestre_id = props.location.state.semestre_id
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [modal, setModal] = useState(false)
  const [matiere, setMatiere] = useState("")
  const [niveau, setNiveau] = useState("")

  const [modalConfirm, setModalConfirm] = useState(false)

  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "ordre",
      text: "Ordre",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "name",
      text: "Nom & Prénom",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Note",
      isDummyField: true,
      text: "Note",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <input
              type="number"
              id={row.etudiant_id}
              onChange={e => changeNote(e, row)}
              defaultValue={row.note}
              style={{ textAlign: "center" }}
          ></input>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await API.post("ds2/list_etudiant_note", {
      ds2_id: state_element.id,
    }).then(res => {
      setList(res.data.ListEtudiant.data_arr)
      setMatiere(res.data.ListEtudiant.matiere)
      setNiveau(res.data.ListEtudiant.niveau)
      setLoading(true)
    })
  }, [])

  const changeNote = (event, element) => {
    if (/^[0-9,.]+$/.test(event.target.value)) {
      var note = event.target.value
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    var note = event.target.value
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setList(
          list.map((el, id) =>
              el.etudiant_id === element.etudiant_id
                  ? Object.assign(el, { note: note })
                  : el
          )
      )
    } else if (note == "") {
      setList(
          list.map((el, id) =>
              el.etudiant_id === element.etudiant_id
                  ? Object.assign(el, { note: "" })
                  : el
          )
      )
    } else {
      setModal(true)
      var note1 = note.slice(0, -1)
      $("#" + element.etudiant_id).val(note1)
    }
  }

  const close = () => {
    setModal(false)
  }

  const toggleConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const save = async () => {
    const res = await API.post("ds2/add_note_scolarite", {
      user_id: user_id,
      list: list,
      ds2_id: state_element.id,
    })
        .then(res => {
          setModalConfirm(false)
          props.history.push({
            pathname: "/ListMatieresNoteDS2",
            state: {
              element: element,
              niveau_id: niveau_id,
              semestre_id: semestre_id,
            },
          })
        })
        .catch(() => {
          toast.error("⛔  Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
  }

  const importList = async () => {
    const res = await API.post(
        "ds2/export",
        {
          ds2_id: state_element.id,
        },
        { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "NotesExamenDS2.xls")
    })
  }

  return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Niveau :  <span style={{ color: "#556ee6" }} >{niveau}</span>
                </CardTitle>
                <CardTitle className="h4 mb-4">
                  Matière : <span style={{ color: "#556ee6" }} >{matiere} </span>
                </CardTitle>
                {loading ? (
                    <Row className="mt-4">
                      <Col xs="12">
                        <ToolkitProvider
                            keyField="id"
                            data={list}
                            columns={Columns()}
                            search
                            bootstrap4
                        >
                          {toolkitProps => (
                              <div>
                                <Row className="mb-2">
                                  <Col sm="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                          type="button"
                                          color="success"
                                          className="btn mb-2 me-2"
                                          onClick={importList}
                                      >
                                        <i className="fas fa-file-import me-1" />
                                        Import
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    noDataIndication={() => <NoDataIndication />}
                                    striped={false}
                                    bordered={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"table-light"}
                                    hover
                                    {...toolkitProps.baseProps}
                                />
                              </div>
                          )}
                        </ToolkitProvider>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                              <Button
                                  type="button"
                                  color="warning"
                                  className="btn btn-warning  mb-2 me-2"
                                  onClick={() =>
                                      props.history.push({
                                        pathname: "/ListMatieresNoteDS2",
                                        state: {
                                          element: element,
                                          niveau_id: niveau_id,
                                          semestre_id: semestre_id,
                                        },
                                      })
                                  }
                              >
                                Annuler
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                              <button
                                  type="button"
                                  className="btn btn-primary "
                                  onClick={toggleConfirm}
                              >
                                CONFIRMER
                              </button>
                            </div>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                ) : (
                    <div>
                      <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                      >
                        <Spinner type="grow" className="ms-6" color="primary" />
                      </div>
                      <h4
                          style={{ textAlign: "center", marginTop: "2%" }}
                          className="ms-6"
                      >
                        Chargement ...
                      </h4>
                    </div>
                )}
              </CardBody>
            </Card>
          </Container>
        </div>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />
        <Modal isOpen={modal} centered={true}>
          <ModalHeader style={{ color: "red" }} toggle={close} tag="h4">
            Faite Attention
          </ModalHeader>
          <ModalBody>
            <div className="d-flex justify-content-center">
              <img
                  src={warning}
                  alt=""
                  style={{ width: 100, textAlign: "center" }}
              />
            </div>
            <p className="mt-4" style={{ textAlign: "center" }}>
              <strong> Veuillez verifier la note saisie </strong>
            </p>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalConfirm} toggle={toggleConfirm} centered={true}>
          <ModalHeader
              style={{ width: "100% !important", textAlign: "center !important" }}
              toggle={toggleConfirm}
              tag="h4"
          >
            Confirmation
          </ModalHeader>
          <ModalBody>
            <div
                style={{
                  fontFamily: "Montserrat, sans-serif",
                  FontSize: "14px",
                  FontWeight: "700",
                  LineHeight: "18.375px",
                }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>Vos étes sur de changer des notes ?</p>
              </div>
              <div
                  className="hvr-push mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginBottom: "15px",
                  }}
              >
                <div>
                  <Button color="info" className="btn-rounded " onClick={save}>
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                      onClick={toggleConfirm}
                      color="danger"
                      className="btn-rounded "
                  >
                    <i
                        style={{ color: "white" }}
                        className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
  )
}

export default withRouter(ListEtudiantNoteDS2)
ListEtudiantNoteDS2.propTypes = {
  list: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.any,
}
