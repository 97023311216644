import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import {
    Container,
    Card,
    CardBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    Col,
    Spinner,
    Modal,
    ModalBody,
    Button, CardTitle, Form, Label,
} from "reactstrap"
import classnames from "classnames"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import API from "../../api"
import DatePicker from "react-datepicker";
import getDay from "date-fns/getDay"
import {Slide, ToastContainer, toast } from "react-toastify";
 import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
const AttestationPresenceArchive = props => {
    const [section, setSection] = useState(1)
    const [loading, setLoading] = useState(false)
    const [dateDebut, setDateDebut] = useState(new Date())
    const [dateFin, setDateFin] = useState(new Date())

    const isWeekday = date => {
        const day = getDay(date)
        return day !== 0
    }
    // Data Table
    const [orders, setOrders] = useState([])
    const [modalVal, setModalVal] = useState(false)
    const [idVal, setIdVal] = useState("")

    const pageOptions = {
        loading: true,
        hidePageListOnlyOnePage: true,
        totalSize: orders.length,
        page: 1,
        nextPageText: "Next",
        prePageText: "Back",
        alwaysShowAllBtns: true,
        sizePerPage: 30,
        sizePerPageList: [
            {
                text: "5",
                value: 5,
            },
            {
                text: "10",
                value: 10,
            },
            {
                text: "20",
                value: 20,
            },
            {
                text: "30",
                value: 30,
            },
            {
                text: "All",
                value: orders.length,
            },
        ],
    }

    const NoDataIndication = () => (
        <h6 style={{ textAlign: "center" }}>No record to display</h6>
    )
    const Columns = () => [
        {
            dataField: "date",
            text: "Date",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "code",
            text: "CIN",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "etudiant",
            text: "Etudiant",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "groupe",
            text: "Groupe",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "raison",
            text: "Raison",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        },
        {
            dataField: "langue",
            text: "Langue",
            sort: true,
            style: { textAlign: "center" },
            headerStyle: (colum, colIndex) => {
                return { textAlign: "center" }
            },
        }
    ]

    const { SearchBar } = Search

    useEffect(async () => {
        const res = await API.post("scolarite/etudiant/demande/listArchive", {
            id: 1,
        }).then(res => {
            setOrders(res.data.Demandes)
        })
        setLoading(true)
    }, [])

    const search = async () => {
        if (dateDebut != "" && dateFin!="") {
            //date debut
            let DateNote_debut = dateDebut
            let month_debut = "" + (DateNote_debut.getMonth() + 1)
            let day_debut = "" + DateNote_debut.getDate()
            let year_debut = DateNote_debut.getFullYear()
            if (month_debut.length < 2) month_debut = "0" + month_debut
            if (day_debut.length < 2) day_debut = "0" + day_debut
            var convertDate_debut = [year_debut, month_debut, day_debut].join("-")
            ///date fin
            let DateNote_fin = dateFin
            let month_fin = "" + (DateNote_fin.getMonth() + 1)
            let day_fin = "" + DateNote_fin.getDate()
            let year_fin = DateNote_fin.getFullYear()
            if (month_fin.length < 2) month_fin = "0" + month_fin
            if (day_fin.length < 2) day_fin = "0" + day_fin
            var convertDate_fin = [year_fin, month_fin, day_fin].join("-")
            const res = await API.post("scolarite/etudiant/demande/search", {
                id: 1,
                date_debut: convertDate_debut,
                date_fin: convertDate_fin,
            }).then(res => {
                setOrders(res.data.Demandes)
            })
        }else{
            toast.error("⛔ Date début et date fin sont obligatroire !", {
                containerId: "A",
            })
        }

    }

    const searchAll = async () => {
        const res = await API.post("scolarite/etudiant/demande/listArchive", {
            id: 1,
        }).then(res => {
            setOrders(res.data.Demandes)
            setDateDebut(new Date())
            setDateFin(new Date())
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Archive Attestation De Présence | Cresus </title>
                </MetaTags>
                <Container fluid>
                    <div>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: section === 1,
                                    })}
                                    onClick={() => {
                                        setSection(1)
                                    }}
                                >
                                  Archive Attestation De Présence
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <Card>
                            <CardBody>
                                <CardTitle style={{ color: "#556ee6" }} className="h4">
                                    Critéres de Recherches
                                </CardTitle>
                                <Form className="mt-4">

                                    <Row>
                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label for="basicpill-lastname-input2">Date Début :</Label>
                                                <DatePicker
                                                    selected={dateDebut}
                                                    filterDate={isWeekday}
                                                    className="form-control ddate"
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={e => setDateDebut(e)}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label for="basicpill-lastname-input2">Date Fin :</Label>
                                                <DatePicker
                                                    selected={dateFin}
                                                    filterDate={isWeekday}
                                                    className="form-control ddate"
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={e => setDateFin(e)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <div
                                    className="mb-4"
                                    style={{ display: "flex", justifyContent: "space-around" }}
                                >
                                    {" "}
                                    <Col lg="6">
                                        <div className="text-center mt-4">
                                            <button
                                                type="button"
                                                style={{
                                                    backgroundColor: "#761C19",
                                                    borderColor: "#761C19",
                                                }}
                                                className="btn btn-success mb-2 me-2"
                                                onClick={search}
                                            >
                                                Rechercher
                                            </button>
                                        </div>
                                    </Col>
                                    <Col lg="6">
                                        <div className="text-center mt-4">
                                            <button
                                                type="button"
                                                style={{
                                                    backgroundColor: "#761C19",
                                                    borderColor: "#761C19",
                                                }}
                                                className="btn btn-success mb-2 me-2"
                                                onClick={searchAll}
                                            >
                                                Afficher tout
                                            </button>
                                        </div>
                                    </Col>
                                </div>




                                <React.Fragment>
                                    <div>
                                        {loading ? (
                                            <Row>
                                                <Col xs="12">
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={orders}
                                                        columns={Columns()}
                                                        search
                                                        bootstrap4
                                                    >
                                                        {toolkitProps => (
                                                            <div>
                                                                <Col sm="4">
                                                                    <div className="search-box me-2 mb-2 d-inline-block">
                                                                        <div className="position-relative">
                                                                            <SearchBar
                                                                                {...toolkitProps.searchProps}
                                                                            />
                                                                            <i className="bx bx-search-alt search-icon" />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <BootstrapTable
                                                                    wrapperClasses="table-responsive"
                                                                    noDataIndication={() => <NoDataIndication />}
                                                                    striped={false}
                                                                    bordered={false}
                                                                    classes={"table align-middle table-nowrap"}
                                                                    headerWrapperClasses={"table-light"}
                                                                    hover
                                                                    pagination={paginationFactory(pageOptions)}
                                                                    {...toolkitProps.baseProps}
                                                                />
                                                            </div>
                                                        )}
                                                    </ToolkitProvider>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    {" "}
                                                    <Spinner
                                                        type="grow"
                                                        className="ms-6"
                                                        color="primary"
                                                    />
                                                </div>
                                                <h4
                                                    style={{ textAlign: "center", marginTop: "2%" }}
                                                    className="ms-6"
                                                >
                                                    {" "}
                                                    En Cours ...{" "}
                                                </h4>
                                            </div>
                                        )}
                                    </div>

                                </React.Fragment>
                            </CardBody>
                        </Card>
                    </div>
                </Container>
                <ToastContainer
                    transition={Slide}
                    enableMultiContainer
                    containerId={"A"}
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={2500}
                />
            </div>
        </React.Fragment>
    )
}

export default withRouter(withTranslation()(AttestationPresenceArchive))
AttestationPresenceArchive.propTypes = {
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
}
