import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import Select from "react-select"
import {
  Col,
  Input,
  Row,
  Label,
  Form,
  Button,
  Container,
  Card,
  CardBody,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"

const EditCycle = props => {
  const [cycle, setCycle] = useState("")
  const [niveaux, setNiveaux] = useState([])
  const [selectNiveaux, setSelectNiveaux] = useState("")
  //

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("cycle/get_by_id", { id }).then(res => {
      setCycle(res.data.Cycle.name)
    })
    const resN = await API.post("niveau/get_by_cycle", { id }).then(resN => {
      setSelectNiveaux(resN.data.Niveaux_by_cycle)
    })
  }, [])

  useEffect(async () => {
    const res = await API.get("getniveau/list").then(res => {
      setNiveaux(res.data.Niveau)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("cycle/update", {
      id: id,
      name: cycle,
      niveaux: selectNiveaux,
    })
      .then(res => {
        props.history.push("/Cycle")
      })
      .catch(() => {
        toast.error("⛔  Problème lors de l'insertion !", {
          containerId: "A",
        })
      })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <Row>
                <Form>
                  <Row>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Cycle")}
                        </Label>
                        <Input
                          lg="3"
                          type="text"
                          className="form-control"
                          placeholder={props.t("Cycle")}
                          value={cycle}
                          onChange={e => setCycle(e.target.value)}
                        />
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="mb-3">
                        <Label for="basicpill-firstname-input1">
                          {props.t("Niveau")}
                        </Label>
                        <Select
                          isMulti={true}
                          options={niveaux}
                          isSearchable={true}
                          value={selectNiveaux}
                          onChange={e => setSelectNiveaux(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      className="text-center mt-4"
                    >
                      <Button
                        type="button"
                        color="warning"
                        className="btn btn-warning  mb-2 me-2"
                        onClick={() => props.history.push("/Cycle")}
                      >
                        {props.t("Annuler")}
                      </Button>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                      className="text-center mt-4"
                    >
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={edit}
                      >
                        {props.t("Confirmer")}
                      </button>
                    </div>
                  </Col>
                </div>
              </Row>
            </CardBody>
          </Card>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditCycle)
EditCycle.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
